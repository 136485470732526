<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>
        {{ $t(`community-others.contact.change-responsible`) }}
      </h4>
      <b-button
        variant="primary"
        :disabled="!poweredBy || isSending"
        @click="handleEditItem"
      >
        <span v-if="isSending">
          <b-spinner
            label="Sending..."
            variant="white"
            class="mr-50"
            small
          />
          {{ $t("backoffice.subscriptions.form.action.submit") }}
        </span>
        <span v-else>
          {{ $t("form.actions.save") }}
        </span>
      </b-button>
    </div>
    <b-card
      v-if="item.poweredBy"
      :class="
        showContact
          ? ''
          : 'hide-contact' || type === 'agenda'
            ? ''
            : 'hide-contact'
      "
    >
      <p v-if="type !== 'event-agenda'" class="mb-1 small">
        {{ $t(`community-others.contact.responsible`) }}
      </p>
      <!-- Imatge -->
      <div class="d-flex align-items-center class pb-2 mb-2">
        <b-avatar
          size="25"
          :src="
            item.poweredByType === 'member'
              ? item.poweredBy.avatar_url
              : item.poweredBy.logoURL
          "
        />
        <b-link
          v-if="item.poweredByType === 'community'"
          :href="getCollectiveUrl(item.poweredBy)"
        >
          <h4 class="ml-1 mb-0">
            {{ firstToUpperCase(item.poweredBy.name) }}
          </h4>
        </b-link>
        <b-link
          v-else-if="item.poweredByType === 'member'"
          :to="getMemberLocation(item.poweredBy)"
        >
          <h4 class="ml-1 mb-0">
            {{ firstToUpperCase(item.poweredBy.name) }}
            <span v-if="item.poweredBy.surname">{{
              firstToUpperCase(item.poweredBy.surname)
            }}</span>
          </h4>
        </b-link>
        <b-link v-else :to="getOrganizationLocation(item.poweredBy)">
          <h4 class="ml-1 mb-0">
            {{ firstToUpperCase(item.poweredBy.name) }}
            <span v-if="item.poweredBy.surname">{{
              firstToUpperCase(item.poweredBy.surname)
            }}</span>
          </h4>
        </b-link>
      </div>
      <!-- email -->

      <b-row v-if="(item.poweredBy.emails && item.poweredBy.emails.length > 0)">
        <b-col
          cols="3"
          md="4"
          lg="3"
          class="font-weight-bold"
        >
          {{ $t(`community-others.contact.email`) }}
        </b-col>
        <b-col cols="9" md="8" lg="9">
          <div v-for="(email, index) in item.poweredBy.emails" :key="index">
            <small
              v-if="item.poweredBy.emails.length > 1"
              class="text-muted mb-0"
            >{{ email.label }}</small>
            <p :class="item.poweredBy.emails.length > 1 ? 'mb-25' : ''">
              {{ email.email }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="(item.poweredBy.email)">
        <b-col
          cols="3"
          md="4"
          lg="3"
          class="font-weight-bold"
        >
          {{ $t(`community-others.contact.email`) }}
        </b-col>
        <b-col cols="9" md="8" lg="9">
          <small
            class="text-muted mb-0"
          >{{ item.poweredBy.label }}</small>
          <p :class="item.poweredBy.email ? 'mb-25' : ''">
            {{ item.poweredBy.email }}
          </p>
        </b-col>
      </b-row>
      <!-- Locations -->
      <b-row
        v-if="item.poweredBy.locations && item.poweredBy.locations.length > 0"
      >
        <b-col
          cols="3"
          md="4"
          lg="3"
          class="font-weight-bold"
        >
          {{ $t("backoffice.settings.subtitle-locations") }}
        </b-col>
        <b-col cols="9" md="8" lg="9">
          <p v-for="(location, index) in item.poweredBy.locations" :key="index">
            {{ location.address }}
          </p>
        </b-col>
      </b-row>
      <!-- Phone -->
      <b-row
        v-if="item.poweredBy.phones && item.poweredBy.phones.length > 0"
      >
        <b-col
          cols="3"
          md="4"
          lg="3"
          class="font-weight-bold"
        >
          {{ $t(`community-others.contact.phone`) }}
        </b-col>
        <b-col
          cols="9"
          md="8"
          lg="9"
          class="d-flex flex-column"
        >
          <div v-for="(phone, index) in item.poweredBy.phones" :key="index">
            <small
              v-if="item.poweredBy.phones.length > 1"
              class="text-muted mb-0"
            >
              {{ phone.label }}
            </small>
            <p :class="item.poweredBy.phones.length > 1 ? 'mb-25' : ''">
              <span v-if="phone.country">
                +{{ phone.country.phoneCode }} 
              </span>
              {{ phone.number }}
            </p>
          </div>
        </b-col>
      </b-row>
      <!-- Links -->
      <b-row
        v-if="item.poweredBy.links && item.poweredBy.links.length > 0"
      >
        <b-col
          cols="3"
          md="4"
          lg="3"
          class="font-weight-bold"
        >
          {{ $t("links.name") }}
        </b-col>
        <b-col
          cols="9"
          md="8"
          lg="9"
          class="d-flex"
        >
          <p v-for="(link, index) in item.poweredBy.links" :key="index">
            <a :href="link.url" target="_blank" class="mr-1">
              <feather-icon
                :icon="
                  link.label === 'Website' ? 'GlobeIcon' : `${link.label}Icon`
                "
                size="18"
                class="social-icons"
              />
            </a>
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Tipo de powerBy -->
    <b-form class="w-100">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group v-if="!item.mainType && !isContactShown">
            <b-form-checkbox v-model="showContact">
              {{ $t(`community-others.contact.show-contact`) }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="showContact || isContactShown"
            :label="$t(`community-others.contact.type-responsible`)"
            label-for="layout"
            class="w-100"
          >
            <v-select
              id="layout"
              v-model="poweredByTypeSelected"
              :clearable="false"
              :reduce="(type) => type.value"
              :options="
                isStaff ? poweredByTypeOptionsStaff : poweredByTypeOptions
              "
              label="text"
              @input="changePoweredBy"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <div v-if="showContact || isContactShown">
            <!-- community -->
            <b-form-group
              v-if="poweredByTypeSelected === 'community'"
              :label="$t(`community-others.contact.select-community`)"
              label-for="communities"
            >
              <ajax-select
                id="communities"
                v-model="poweredBy"
                class="style-chooser"
                clearable
                searchable
                dir="ltr"
                input-id="shared-communities"
                :placeholder="$t('shared-communities.modal-placeholder')"
                :fetch-data="fetchCommunities"
                label="name"
                :has-code="true"
                :multiple="false"
                :code="communitiesFilter"
                :clean-options="cleanOptions"
                @changeCleanOptions="changeCleanOptions"
              >
                <template #option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
                <template #selected-option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </ajax-select>
            </b-form-group>
            <!-- organization -->
            <b-form-group
              v-if="poweredByTypeSelected === 'organization'"
              :label="$t(`community-others.contact.select-organization`)"
              label-for="layout"
              class="w-100"
            >
              <members-select
                :key="poweredByTypeSelected"
                v-model="poweredBy"
                class="style-chooser"
                searchable
                :multiple="false"
                :clearable="false"
                :powered-by="item.poweredBy"
                :placeholder="$t('organizations.search-placeholder')"
                dir="ltr"
                input-id="profile-organizations"
                :fetch-data="fetchOrganizations"
                :has-code="true"
                :code="organizationsFilter"
                label="name"
              >
                <template #option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
                <template #selected-option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </members-select>
            </b-form-group>
            <!-- member -->
            <b-form-group
              v-if="poweredByTypeSelected === 'member'"
              :label="$t(`community-others.contact.select-member`)"
              label-for="add-guests"
            >
              <members-select
                :key="poweredByTypeSelected"
                v-model="poweredBy"
                class="style-chooser"
                dir="ltr"
                :multiple="false"
                :clearable="false"
                :placeholder="$t('members.search-placeholder')"
                searchable
                :close-on-select="false"
                :fetch-data="fetchMembers"
                :powered-by="item.poweredBy"
                input-id="add-guests"
                :has-code="true"
                :code="membersFilter"
                label="name"
              >
                <template #option="option">
                  <b-avatar size="sm" :src="option.avatarURL" />
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
                <template #selected-option="option">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="option.avatarURL"
                  />
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </members-select>
            </b-form-group>
            <!-- People -->
            <b-form-group
              v-if="poweredByTypeSelected === 'people'"
              :label="$t(`community-others.contact.select-people`)"
              label-for="people"
            >
              <ajax-select
                id="people"
                v-model="poweredBy"
                class="style-chooser"
                clearable
                searchable
                dir="ltr"
                input-id="shared-communities"
                :placeholder="$t('community-others.contact.select-people-placeholder')"
                :fetch-data="fetchPeople"
                label="name"
                :has-code="true"
                :multiple="false"
                :code="peopleFilter"
                :clean-options="cleanOptions"
                @changeCleanOptions="changeCleanOptions"
              >
                <template #option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
                <template #selected-option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </ajax-select>
            </b-form-group>
            <!-- Entity -->
            <b-form-group
              v-if="poweredByTypeSelected === 'entity'"
              :label="$t(`community-others.contact.select-entity`)"
              label-for="entity"
            >
              <ajax-select
                id="entity"
                v-model="poweredBy"
                class="style-chooser"
                clearable
                searchable
                dir="ltr"
                input-id="shared-communities"
                :placeholder="$t('community-others.contact.select-entity-placeholder')"
                :fetch-data="fetchEntities"
                label="name"
                :has-code="true"
                :multiple="false"
                :clean-options="cleanOptions"
                :code="entitiesFilter"
                @changeCleanOptions="changeCleanOptions"
              >
                <template #option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
                <template #selected-option="option">
                  <span
                    class="ml-50 align-middle"
                  >
                    {{ option.name }}
                  </span>
                </template>
              </ajax-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import vSelect from 'vue-select';
import OrderBy from '@/@core/constants/OrderBy';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { MainType } from '@copernicsw/community-common';

export default {
  name: 'ChangeContact',
  components: {
    MembersSelect,
    vSelect,
    AjaxSelect,
  },
  mixins: [ToastNotificationsMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isFromSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      poweredByTypeOptionsStaff: [
        {
          text: this.$t('backoffice.referrals.type-options.community'),
          value: 'community',
        },
        {
          text: this.$t('backoffice.referrals.type-options.organization'),
          value: 'organization',
        },
        {
          text: this.$t('backoffice.referrals.type-options.member'),
          value: 'member',
        },
        {
          text: this.$t('backoffice.referrals.type-options.people'),
          value: 'people',
        },
        {
          text: this.$t('backoffice.referrals.type-options.entity'),
          value: 'entity',
        },
      ],
      poweredByTypeOptions: [
        {
          text: this.$t('backoffice.referrals.type-options.organization'),
          value: 'organization',
        },
        {
          text: this.$t('backoffice.referrals.type-options.member'),
          value: 'member',
        },
      ],
      poweredByTypeSelected: 'organization',
      poweredBy: '',
      isSending: false,
      showContact: true,
      cleanOptions: false,
    };
  },
  computed: {
    isContactShown() {
      return ['agenda', 'rooms'].includes(this.type);
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    communitySlug() {
      if (this.isFromSettings) {
        if (this.collective.parentCollective.mainType === 'Section') {
          if (this.collective.parentCollective.parentCollective.mainType === 'Section') {
            return this.collective.parentCollective.parentCollective.parentCollective.slug;
          }
          return this.collective.parentCollective.parentCollective.slug;
        }
        return this.collective.parentCollective.slug;
      }
      return this.$store.getters.currentCollective.slug;
    },
  },
  async created() {
    this.showContact = this.item.mainType ? true : this.item.visibility;
    this.poweredByTypeSelected = this.item.poweredByType ?? 'organization';
    this.poweredBy = this.item.poweredBy ? this.item.poweredBy.key : '';
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchOrganizations(page, search = '', force) {
      return this.$store.dispatch('getItems', {
        page,
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: 'organizations',
        forceAPICall: force,
        perPage: this.perPage,
        requestConfig: {
          searchString: search || '',
          isOrganization: true,
          communityParentSlug: this.communitySlug,
        },
      });
    },
    changePoweredBy() {
      this.cleanOptions = true;
      if (this.poweredByTypeSelected === this.item?.poweredByType) {
        this.poweredBy = this.item.poweredBy.key;
      } else {
        this.poweredBy = '';
      }
    },
    changeCleanOptions() {
      this.cleanOptions = false;
    },
    organizationsFilter(response) {
      const organizationOptions = [];
      response.forEach((organization) => organization && organizationOptions.push({
        code: organization.key,
        name: this.translate(organization.name),
      }));
      return organizationOptions;
    },
    async fetchCommunities(page, search = '', force = false) {
      return this.$store.dispatch('getItems', {
        itemType: 'communities/wholeBranch',
        customName: 'sharedCommunities',
        page,
        perPage: 16,
        forceAPICall: force,
        storedKey: 'allCommunities',
        requestConfig: {
          searchString: search || '',
          showMe: true,
          communitySlug: this.communitySlug,
          mainTypeIDs: [6, 7, MainType.Event, MainType.Course, MainType.Project, MainType.Organization, MainType.Challenge, MainType.Service]

        },
      });
    },
    communitiesFilter(response) {
      const communityOptions = [];
      response.forEach((community) => community && communityOptions.push({
        code: community.key,
        name: this.translate(community.name),
      }));
      return communityOptions;
    },
    async fetchMembers(page, search = '', force) {
      return this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        forceAPICall: force,
        page,
        perPage: 16,
        communitySlug: this.communitySlug,
        requestConfig: {
          searchString: search || '',
          searchEmail: search || '',
        },
      });
    },
    membersFilter(response) {
      const memberOptions = [];
      response.forEach((member) => (member && memberOptions.push({
        code: member.key,
        avatarURL: member.avatarURL,
        name: member.name || member.surname ? `${this.translate(member.name)} ${this.translate(member.surname)}` : member.username,
      })));
      return memberOptions;
    },
    async fetchPeople(page, search = '', force = false) {
      return this.$store.dispatch('getItems', {
        itemType: 'people',
        forceAPICall: force,
        page,
        perPage: 16,
        requestConfig: {
          typeKey: undefined,
          searchString: search || '',
          orderBySurname: OrderBy.ASC,
          communitySlug: this.communitySlug,
        },
      });
    },
    peopleFilter(response) {
      const peopleOptions = [];
      response.forEach((person) => (person && peopleOptions.push({
        code: person.key,
        avatarURL: person.avatarURL,
        name: person.name || person.surname ? `${this.translate(person.name)} ${this.translate(person.surname)}` : member.username,
      })));
      return peopleOptions;
    },
    async fetchEntities(page, search = '', force = false) {
      return this.$store.dispatch('getItems', {
        itemType: 'entities',
        forceAPICall: force,
        page,
        requestConfig: {
          orderByDate: -1,
          searchString: search || '',
          count: 100,
          communitySlug: this.communitySlug,
        },
      });
    },
    entitiesFilter(response) {
      const entitiesOptions = [];
      response.forEach((entity) => (entity && entitiesOptions.push({
        code: entity.key,
        name: this.translate(entity.name),
      })));
      return entitiesOptions;
    },
    async handleEditItem() {
      this.isSending = true;
      try {
        if (this.isFromSettings) {
          await this.$store.dispatch('editCommunity', {
            settings: {
              poweredByKey: this.poweredBy,
              poweredByType: this.poweredByTypeSelected,
            },
          });
          this.isSending = false;
          this.notifySuccess(this.$t('success-message.general-success-edit'));
        } else {
          this.$emit(
            'handleEditItem',
            this.poweredBy,
            this.poweredByTypeSelected,
            this.showContact,
          );
        }
        this.isSending = false;
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    firstToUpperCase(item) {
      const name = typeof item.key === 'string' ? item : this.translate(item);
      const firstLetter = name[0];
      if(!firstLetter){
        return '?';
      }
      return firstLetter.toUpperCase() + name.substring(1);
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: 'member-details',
        params: {
          username: user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    getCollectiveUrl(item, params = '') {
      if (item != null) {
        return getCollectiveUrl(item, params);
      }
      return '';
    },
    getOrganizationLocation(organization) {
      if (!organization.name) {
        return null;
      }

      return {
        name: 'organization',
        params: {
          slug: organization.slug,
          communityId: this.$route.params.communityId,
        },
      };
    },
  },

};
</script>

<style>

</style>
